import { ProjectSettings } from '@valiot/settings'
import { LogoFactoryos } from './logoFactoryos'

const settings: ProjectSettings = {
  title: 'Valiot-Factoryos-Demo',
  copilotEnabled: false,
  api: {
    http: import.meta.env.VITE_API_URL,
    wss: import.meta.env.VITE_API_WSS,
    valiotWorker: import.meta.env.VITE_WORKER,
    ssoUrl: import.meta.env.VITE_SSO_URL,
  },
  products: 'FactoryOS',
  alerts: {
    byRoles: {
      Admin: {
        severities: 'all',
        levels: 'all',
        codes: ['DEMO_CONSIGN_ALERT', 'DEMO_CONSIGN_IGNORED'],
      },
      Molienda: {
        codes: [
          'Muestras de Molienda',
          'CONTROL_OUTPUT_DEFLOCCULATING_FLOW_NN',
          'CONTROL_OUTPUT_MILL_FEED_FLOW_NN',
          'CONTROL_OUTPUT_WATER_FLOW_GRINDING_NN',
          'FEEDBACK_DEFLOCCULATING_FLOW_NN',
          'FEEDBACK_MILL_FEED_FLOW_NN',
          'FEEDBACK_WATER_FLOW_GRINDING_NN',
        ],
      },
      Atomizador: {
        codes: [
          'Muestras de Rendimiento',
          'Muestra de Gas',
          'Muestras de Granulometría Atomizador',
          'CONTROL_OUTPUT_Atomizador_Apertura_CompuertaQuemador_NN',
          'CONTROL_OUTPUT_Atomizador_Presion_Torre_NN',
          'FEEDBACK_Atomizador_Apertura_CompuertaQuemador_NN',
          'FEEDBACK_Atomizador_Presion_Torre_NN',
        ],
      },
      Esmaltado: {
        codes: [
          'Muestra de Engobe',
          'Muestra de Base',
          'CONTROL_OUTPUT_Esmaltado5_Base_Estado_Llave_Velocidad_Banda_NN',
          'CONTROL_OUTPUT_Esmaltado5_Base_Tiempo_Agua_NN',
          'CONTROL_OUTPUT_Esmaltado5_Base_Tiempo_Base_NN',
          'CONTROL_OUTPUT_Esmaltado5_Engobe_Airless_Pressure_Velocidad_Banda_NN',
          'CONTROL_OUTPUT_Esmaltado5_Engobe_Tiempo_Agua_NN',
          'CONTROL_OUTPUT_Esmaltado5_Engobe_Tiempo_Engobe_NN',
          'FEEDBACK_Esmaltado5_Base_Estado_Llave_Velocidad_Banda_NN',
          'FEEDBACK_Esmaltado5_Base_Tiempo_Agua_NN',
          'FEEDBACK_Esmaltado5_Base_Tiempo_Base_NN',
          'FEEDBACK_Esmaltado5_Engobe_Airless_Pressure_Velocidad_Banda_NN',
          'FEEDBACK_Esmaltado5_Engobe_Tiempo_Agua_NN',
          'FEEDBACK_Esmaltado5_Engobe_Tiempo_Engobe_NN',
        ],
      },
    },
  },
  analytics: {
    enabled: true,
    siteId: import.meta.env.VITE_MATOMO_SITE_ID,
  },
  locale: {
    default: 'auto',
    projectId: import.meta.env.VITE_LOCIZE_PROJECT_ID,
  },
  support: {
    enabled: false,
  },
  customerLogo: {
    src: (
      <div>
        <LogoFactoryos />
      </div>
    ),
  },
  sidebarDefault: 'expanded',
  home: 'realTimeProcess',
  routes: [
    {
      path: 'realTimeProcess',
      text: 'Real Time Process',
      iconSource: 'fas',
      icon: 'radar',
      component: 'RealTimeProcess',
      exactUrl: false,
      sidebarDefault: 'collapsed',
    },
    {
      path: 'kpis',
      text: 'KPI',
      iconSource: 'far',
      icon: 'bullseye-arrow',
      component: 'KPIS',
      exactUrl: false,
      sidebarDefault: 'collapsed',
    },
    {
      path: 'spc',
      text: 'Statistical Process Control',
      iconSource: 'fas',
      icon: 'analytics',
      component: 'SPC',
      exactUrl: false,
      sidebarDefault: 'collapsed',
    },
    {
      path: 'orders',
      text: 'Orders',
      iconSource: 'fas',
      icon: 'cog',
      component: 'Orders',
      exactUrl: false,
      sidebarDefault: 'collapsed',
    },
    {
      path: 'stops',
      text: 'Stops',
      iconSource: 'fas',
      icon: 'cog',
      component: 'Stops',
      exactUrl: false,
      sidebarDefault: 'collapsed',
    },
    {
      path: 'manage-stop-reasons',
      text: 'Manage Stop reasons',
      component: 'ManageStopReasons',
      exactUrl: false,
      sidebarDefault: 'collapsed',
      hidden: true,
    },
    {
      path: 'dynamic-forms',
      text: 'Dynamic Forms',
      iconSource: 'fas',
      icon: 'cog',
      component: 'DynamicForms',
      exactUrl: false,
      sidebarDefault: 'collapsed',
    },
    {
      path: 'forms-hub',
      text: 'Forms Hub',
      component: 'FormsHub',
      exactUrl: false,
      sidebarDefault: 'collapsed',
      hidden: true,
    },
    {
      path: 'historical-data',
      text: 'Historical Data',
      iconSource: 'fas',
      icon: 'cog',
      component: 'HistoricalData',
      exactUrl: false,
      sidebarDefault: 'collapsed',
      hidden: true,
    },
    {
      path: 'realtimevariablegrid',
      text: 'RealTimeVariableGrid',
      iconSource: 'fas',
      icon: 'tv-retro',
      component: 'RealTimeVariableGrid',
      exactUrl: false,
      sidebarDefault: 'collapsed',
    },
  ],

  dropdowns: [
    {
      directory: 'Config',
      text: 'Config',
      iconSource: 'fas',
      icon: 'cog',
      path: 'config',
      permissions: ['Developer', 'Admin', 'Config', 'AdminPlanta'],
      routes: [
        {
          path: 'ranges',
          text: 'Variable Ranges',
          iconSource: 'fas',
          icon: 'sensor-alert',
          component: 'VariableRangeConfig',
        },
      ],
    },
  ],
}

export default settings
