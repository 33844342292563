import React from 'react'

export const LogoFactoryos: React.FC = () => (
  <svg width='32' height='32' viewBox='0 0 164 165' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M128.54 4.80057C128.54 84.1014 90.0856 162.002 42.641 162.002C-4.80364 162.002 -14.1518 114.885 33.0497 2.00043'
      stroke='url(#paint0_linear)'
      strokeLinecap='round'
      strokeLinejoin='round'></path>
    <path
      d='M128.535 4.80014C134.433 87.397 98.5585 162.002 51.1139 162.002C3.66924 162.002 -8.91157 111.151 33.0444 2'
      stroke='url(#paint1_linear)'
      strokeLinecap='round'
      strokeLinejoin='round'></path>
    <path
      d='M128.536 4.80014C140.332 90.693 107.037 162.002 59.5971 162.002C12.1567 162.002 -3.66527 107.421 33.0451 2'
      stroke='url(#paint2_linear)'
      strokeLinecap='round'
      strokeLinejoin='round'></path>
    <path
      d='M128.54 4.80014C146.23 93.989 115.52 162.002 68.0798 162.002C20.6394 162.002 1.57629 103.687 33.0497 2'
      stroke='url(#paint3_linear)'
      strokeLinecap='round'
      strokeLinejoin='round'></path>
    <path
      d='M128.54 4.80014C152.119 97.285 123.998 162.002 76.5574 162.002C29.1171 162.002 6.82555 99.9575 33.0491 2'
      stroke='url(#paint4_linear)'
      strokeLinecap='round'
      strokeLinejoin='round'></path>
    <path
      d='M128.54 4.80014C158.022 100.581 132.476 162.002 85.0403 162.002C37.6042 162.002 12.0715 96.2276 33.0495 2'
      stroke='url(#paint5_linear)'
      strokeLinecap='round'
      strokeLinejoin='round'></path>
    <path
      d='M128.54 4.80014C163.937 103.877 140.954 162.002 93.5141 162.002C46.0738 162.002 17.317 92.4941 33.0495 2'
      stroke='url(#paint6_linear)'
      strokeLinecap='round'
      strokeLinejoin='round'></path>
    <path
      d='M128.537 4.80014C169.811 107.173 149.434 162.002 101.989 162.002C54.5448 162.002 22.5597 88.7752 33.0466 2'
      stroke='url(#paint7_linear)'
      strokeLinecap='round'
      strokeLinejoin='round'></path>
    <path
      d='M128.531 4.80014C175.703 110.469 157.907 162.002 110.462 162.002C63.0174 162.002 27.7996 85.0307 33.0409 2'
      stroke='url(#paint8_linear)'
      strokeLinecap='round'
      strokeLinejoin='round'></path>
    <path
      d='M128.537 4.80014C181.607 113.765 166.39 162.002 118.946 162.002C71.5009 162.002 33.0463 81.3008 33.0463 2'
      stroke='url(#paint9_linear)'
      strokeLinecap='round'
      strokeLinejoin='round'></path>
    <defs>
      <linearGradient id='paint0_linear' x1='2' y1='162.002' x2='128.54' y2='162.002' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#008EFA'></stop>
        <stop offset='1' stopColor='#76C77C'></stop>
      </linearGradient>
      <linearGradient
        id='paint1_linear'
        x1='7.58081'
        y1='162.002'
        x2='129.171'
        y2='162.002'
        gradientUnits='userSpaceOnUse'>
        <stop stopColor='#008EFA'></stop>
        <stop offset='1' stopColor='#76C77C'></stop>
      </linearGradient>
      <linearGradient
        id='paint2_linear'
        x1='12.8041'
        y1='162.002'
        x2='130.925'
        y2='162.002'
        gradientUnits='userSpaceOnUse'>
        <stop stopColor='#008EFA'></stop>
        <stop offset='1' stopColor='#76C77C'></stop>
      </linearGradient>
      <linearGradient
        id='paint3_linear'
        x1='17.6'
        y1='162.002'
        x2='133.602'
        y2='162.002'
        gradientUnits='userSpaceOnUse'>
        <stop stopColor='#008EFA'></stop>
        <stop offset='1' stopColor='#76C77C'></stop>
      </linearGradient>
      <linearGradient
        id='paint4_linear'
        x1='21.905'
        y1='162.002'
        x2='137.037'
        y2='162.002'
        gradientUnits='userSpaceOnUse'>
        <stop stopColor='#008EFA'></stop>
        <stop offset='1' stopColor='#76C77C'></stop>
      </linearGradient>
      <linearGradient
        id='paint5_linear'
        x1='25.6422'
        y1='162.002'
        x2='141.116'
        y2='162.002'
        gradientUnits='userSpaceOnUse'>
        <stop stopColor='#008EFA'></stop>
        <stop offset='1' stopColor='#76C77C'></stop>
      </linearGradient>
      <linearGradient
        id='paint6_linear'
        x1='28.722'
        y1='162.002'
        x2='145.734'
        y2='162.002'
        gradientUnits='userSpaceOnUse'>
        <stop stopColor='#008EFA'></stop>
        <stop offset='1' stopColor='#76C77C'></stop>
      </linearGradient>
      <linearGradient
        id='paint7_linear'
        x1='31.0505'
        y1='162.002'
        x2='150.785'
        y2='162.002'
        gradientUnits='userSpaceOnUse'>
        <stop stopColor='#008EFA'></stop>
        <stop offset='1' stopColor='#76C77C'></stop>
      </linearGradient>
      <linearGradient
        id='paint8_linear'
        x1='32.5237'
        y1='162.002'
        x2='156.224'
        y2='162.002'
        gradientUnits='userSpaceOnUse'>
        <stop stopColor='#008EFA'></stop>
        <stop offset='1' stopColor='#76C77C'></stop>
      </linearGradient>
      <linearGradient id='paint9_linear' x1='33.0463' y1='162.002' x2='162' y2='162.002' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#008EFA'></stop>
        <stop offset='1' stopColor='#76C77C'></stop>
      </linearGradient>
    </defs>
  </svg>
)
